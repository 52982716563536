<template>
  <div
    :style="{ width: switchWidth, height: switchHeight }"
    class="switch"
    :class="{ 'switch-on': isChecked }"
    @click="toggleSwitch"
  >
    <div
      :style="{ width: handleWidth, height: handleHeight }"
      class="switch-handle"
      :class="{ 'switch-handle-on': isChecked }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "BaseSwitch",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    switchColor: {
      type: String,
      default: "#ccc",
    },
    switchActiveColor: {
      type: String,
      default: "#7ac943",
    },
    switchHoverColor: {
      type: String,
      default: "#ddd",
    },
    switchWidth: {
      type: [String, Number],
      default: "20px", // Default switch width
    },
    switchHeight: {
      type: [String, Number],
      default: "8px", // Default switch height
    },
    handleWidth: {
      type: [String, Number],
      default: "12px", // Default handle width
    },
    handleHeight: {
      type: [String, Number],
      default: "12px", // Default handle height
    },
  },
  data() {
    return {
      isChecked: this.value,
      isHovered: false,
    };
  },
  watch: {
    value(newValue) {
      this.isChecked = newValue;
    },
    isChecked(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    toggleSwitch() {
      this.isChecked = !this.isChecked;
    },
  },
};
</script>

<style>
/* Add CSS for your switch component */
.switch {
  width: 25px;
  height: 10px;
  border-radius: 20px;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.switch:hover {
  background-color: #ddd;
  /* Change to switchHoverColor if provided as a prop */
}

.switch-handle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.switch-handle-on {
  background-color: #16be98;
}

.switch-on {
  background-color: #96ead7;
}

.switch-on .switch-handle {
  transform: translateX(10px) translateY(-50%);
}
</style>
