<template>
  <div :style="{ width: width }" style="height: fit-content" class="px-1">
    <slot />
  </div>
</template>

<script>
export default {
  name: "FieldItemContainer",
  props: {
    width: {
      type: String,
      default: "20%", // Set a default width if none is provided
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
